.element-invisible {
  border: 0 none;
  height: 1px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// Inline turns list items into inline-block
.inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px;

  > li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.article-info {
  font-size: 0.9em;
  dd {
    display: inline-block;
    margin-left: 0;
    margin-right: 10px;
    white-space: nowrap;
  }
}

.muted {
  color: @text-muted;
}

.tagspopular {
  ul {
    display: block;
    list-style: outside none none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    > li {
      color: @text-color;
      display: inline-block;
      margin: 0 5px 5px 0;
      > a {
        background: none repeat scroll 0 0 @gray-lighter;
        border-radius: 3px;
        color: @text-color;
        display: block;
        padding: 2px 5px;
        &:hover {
          background: none repeat scroll 0 0 @link-hover-color;
          color: @gray-lighter;
          text-decoration: none;
        }
      }
    }
  }
}

.tagssimilar {
  ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
    > li {
      border-bottom: 1px solid @hr-border;
      padding: 8px 0;
      > a {
        color: @text-color;
        &:hover {
          color: @link-hover-color;
          text-decoration: none;
        }
      }
    }
  }
}

//
// CATEGORY LIST
// ---------------------------------------------------------
.categories-list {
  margin-bottom: @line-height-computed;
  .clearfix();

  .page-header {
    line-height: normal;
    padding: (@line-height-computed / 2) 5px;
    margin: 0;
    font-size: @font-size-large;
    font-weight: normal;
  }

  // Sub Categories
  .category-item {
    margin-bottom: 0;
    margin-left: @grid-gutter-width;
    .page-header {
      font-size: @font-size-base;
    }
  }
}

.readmore {
  > .btn {
    background-color: @btn-default-bg;
    border-color: @btn-default-border;
    color: @btn-default-color;
  }
}

.counter {
  border-radius: 4px;
  display: inline-block;
  margin-top: 20px;
  padding: 6px 12px;
}

// Striped
// -------
.list-striped,
.row-striped {
  list-style: none;
  line-height: @line-height-computed;
  text-align: left;
  vertical-align: middle;
  border-top: 1px solid @table-border-color;
  margin-left: 0;
  padding-left: 0;
}

.list-striped li,
.list-striped dd,
.row-striped .row,
.row-striped .row-fluid {
  border-bottom: 1px solid @table-border-color;
  padding: 10px 15px;
}

.list-striped li:nth-child(odd),
.list-striped dd:nth-child(odd),
.row-striped .row:nth-child(odd),
.row-striped .row-fluid:nth-child(odd) {
  background-color: @table-bg-accent;
}

.list-striped li:hover,
.list-striped dd:hover,
.row-striped .row:hover,
.row-striped .row-fluid:hover {
  background-color: @table-bg-hover;
}

.row-striped .row-fluid {
  width: 97%; // lower than 100% since we have padding
}

.row-striped .row-fluid [class*="span"] {
  min-height: 10px;
}

.row-striped .row-fluid [class*="span"] {
  margin-left: 8px;
}

.row-striped .row-fluid [class*="span"]:first-child {
  margin-left: 0;
}
#main-body {
  .tz-module {
    margin-bottom: 45px;
    h3 {
      font-size: 20px;
    }
    .nav {
      .open {
        a {
          background: none;
          &:hover {
            background: none;
            color: @mainColor;
          }
          &:focus {
            background: none;
            color: @mainColor;
          }
        }
      }
      li {
        a {
          padding: 0 0 15px;
          &:hover {
            background: none;
            color: @mainColor;
          }
        }
        border-bottom: 1px solid @gray-lighter;
        margin: 15px 0;
        &:first-child {
          margin-top: 0;
        }
      }
    }
    .latestnews {
      margin: 0;
      padding: 0;
      li {
        display: table;
        .media {
          display: table-cell;
          vertical-align: middle;
          width: 80px;
          height: 80px;
          margin-right: 10px;
          img {
            width: 100%;
          }
        }
        .info {
          padding-left: 10px;
          display: table-cell;
          vertical-align: middle;
          time {
            font-size: 13px;
            font-style: italic;
            font-weight: 300;
          }
          a {
            font-size: 15px;
          }
        }
      }
    }
    #login-form {
      .unstyled {
        margin-top: 20px;
      }
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }
      .btn {
        color: #fff;
      }
    }
  }
  .brochures {
    p{
      margin-bottom: 10px;
      a {
        width: 100%;
        text-align: left;
        padding: 12px 20px;
      }
    }
  }
}

.pagination {
  display: inline-block;
  padding: 0;
  margin: 0;
  ul {

    li {
      a {
        background: #f8f6f1;
        color: @mainTextColor;
        border-radius: 0 !important;
        min-width: 40px;
        text-align: center;
        margin: 0 3px;
        line-height: 1.6;
        &:hover {
          background: lighten(#f8f6f1, 10%);
        }
      }
      &.active {
        a {
          background: @mainColor;
          color: #fff;
          border-color: @mainColor;
          &:hover {
            background: lighten(@mainColor, 10%);
            border-color: @mainColor;
          }
        }
      }
    }
  }
}

.chzn-container-single {
  font-size: 16px;
  width: auto;
  display: inline-table;
  .chzn-single {
    -moz-appearance: none;
    background: transparent none repeat scroll 0 0;
    border: 1px solid #dadada;
    border-radius: 0;
    box-shadow: none;
    color: #999999;
    cursor: pointer;
    display: block;
    height: auto;
    line-height: 24px;
    padding: 9px 15px;
    position: relative;
    text-align: left;
    &::after {
      color: #999999;
      content: "";
      font-family: FontAwesome;
      font-size: 14px;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translate(0px, -50%);
    }
    > div {
      display: none;
    }
  }
  .chzn-drop {
    border-radius: 0;
    text-align: left;
  }
  .chzn-results li {
    padding: 8px 11px;
  }
}
.chzn-container-active.chzn-with-drop{
  .chzn-single {
    background: none;
  }
  .chzn-drop {
    .chzn-results li.highlighted{
      background: @mainColor;
    }
  }
}
.newsfeed {
  ol {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      padding: 7px 25px;
      border: 1px solid #ebebeb;
      margin: 30px 0;
    }
  }
}
.tag-category {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      h3 {
        &:before {
          font-family: "FontAwesome";
          content: "\f02b";
        }
      }
    }
  }
}
