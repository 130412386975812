//#tz-menu-wrapper {
//  #plazart-mainnav {
//    margin: 0;
//    border: none;
//    .navbar-collapse {
//      padding: 0;
//    }
//  }
//  border-top: 1px solid @plazart-border-color;
//  border-bottom: 1px solid @plazart-border-color;
//}

//#tz-header-wrapper {
//  padding: 20px 0;
//}

//.tz-module {
//  .nav > {
//    li > {
//      a {
//        display: block;
//        padding: 5px 10px;
//      }
//    }
//  }
//}

//.form-group {
//  margin-bottom: 20px;
//}

//#login-form ul.unstyled {
//  margin-top: 20px;
//  padding-left: 20px;
//}
//
//.module-title {
//  margin-bottom: 20px;
//  font-size: 22px;
//}
//
//.framework-logo {
//  padding: 10px 0 30px;
//}

.border-radius(@radius) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  border-radius: @radius;
}

/* Header -- Masstop */
.tz__block__header--masstop {
  .tz__block__header--masstop--left {
    ul {
      margin-bottom: 0;
      li {
        padding-top: 9px;
        padding-bottom: 9px;
        border-right: 1px solid rgba(255, 255, 255, 0.1);
        margin-right: 20px;
        padding-right: 30px;
        margin-top: 0;
        margin-bottom: 0;
        &:last-child {
          border-right: none;
          padding-right: 0;
          margin-right: 0;
        }
      }
    }
    li [class*='fa'] {
      color: @mainColor;
      margin-right: 5px;
      font-size: 13px;
    }
    p {
      font-weight: 400;
      color: #999999;
      font-size: 14px;
      margin: 0;
      line-height: 27px;
    }
  }
  .tz__block__header--masstop--right {

    text-align: right;
    ul {
      margin-bottom: 0;
      height: 45px;
      display: flex;
      align-items: center;
      float: right;
    }
    .sppb-social-share-wrap {
      width: auto;
      margin: 0;
      float: right;
      .sppb-social-items-wrap {
        padding: 0;
      }
    }
    .sppb-addon-social-share ul {
      margin: 0;
      li {
        padding: 0;
        margin: 0;
        a:hover {
          background: @mainColor !important;
          color: #ffffff;
          i {
            color: #ffffff;
          }
        }
      }
      a {
        border-left: 1px solid rgba(255, 255, 255, 0.1);
      }
      a, i {
        font-size: 13px;
        color: #999999;
        background: transparent;
        width: 45px;
        height: 45px;
        line-height: 45px;
        padding: 0;
      }
    }
  }
}

.tz__menu--inline {
  a {
    color: inherit;
    margin-right: 20px;
    &:hover, &:active, &:focus {
      color: @mainColor;
    }
  }
}

.tz__block__menu {
  > div > .row {
    display: flex;
    align-items: center;
  }
}

/*Menu 2 column*/
.tz__menu--2column {
  > li {
    width: 50%;
    float: left;
    position: relative;
    padding-left: 20px;
    margin-bottom: 20px;
    margin-top: 0 !important;
    &:before {
      content: '\f101';
      font-family: FontAwesome;
      position: absolute;
      top: 0;
      left: 0;
    }
    a {
      color: #999;
      padding: 0;
      background: none !important;
      &:hover {
        color: @mainColor;
      }
    }
    &:hover {
      &:before {
        color: @mainColor;
      }
      > a {
        color: @mainColor;
      }
    }
  }
  li.active > a, li.active:before, li.active > a:hover, {
    color: @mainColor;
  }
  .dropdown-menu {
    > .active > a, .dropdown-menu > .active > a:hover {
      color: @mainColor;
    }
    li {
      padding: 0 20px 5px;
      > a {
      }
    }
  }
  li.parent > a {
    //margin-bottom: 5px;
  }
  .nav-child {
    padding-left: 10px;
    > li {
      padding-bottom: 5px;
      &:first-child {
        padding-top: 5px;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

/*Plazart Menu*/
.plazart-mainnav {
  border: none;
  min-height: 0;
  margin: 0;
  .navbar-collapse.collapse {
    padding: 0;
  }
  .mega-nav > li {
    margin:0;
  }
  .navbar-nav {
    float: none;
    > li {
      float: none;
      display: inline-block;
      margin: 0 -3px 0 0;
      > a {
        font-weight: 600;
        font-size: 15px;
        color: #292524;
        text-transform: uppercase;
        padding-bottom: 40px;
        padding-top: 40px;
        padding-left: 25px;
        padding-right: 25px;
        &:hover, &:focus, &:active {
          background: transparent;
        }
      }
      &.open > a, &.open > a:focus, &.open > a:hover {
        background: transparent;
        color: @mainColor;
      }
      &:first-child {
        margin-left: 0;
        > a {
          padding-left: 0;
        }
      }
      &:last-child {
        margin-right: 0;
        > a {
          padding-right: 0;
        }
      }
    }
  }
  .mega {
    &.mega-align-justify {
      .dropdown-menu {
        left: auto;
        right: 0;
      }
    }
  }
  .dropdown-menu {
    border: none;
    border-top: 2px solid @mainColor;
    .box-shadow(0 3px 5px rgba(0, 0, 0, 0.2));
    .border-radius(0);
    padding: 0;
    margin: 0;
    .mega-col-nav {
      padding: 0;
    }
    .mega-nav > li {
      margin:0;
      > a {
        padding: 15px 25px !important;
        margin: 0;
        border-bottom: 1px solid rgba(221, 221, 221, 0.3);
        border-left: 2px solid transparent;
        &:hover {
          background: transparent !important;
          color: #292524 !important;
          padding-left: 33px !important;
          border-left-color: @mainColor;
        }
      }
      a {
        font-size: 15px;
        font-weight: 300;
        line-height: 20px;
        .transition(all 0.3s linear 0s);
      }
      &:first-child {
        > a {
          margin-top: 0;
        }
      }
      &:last-child {
        > a {
          margin-bottom: 0;
        }
      }
    }
    .mega-inner {
      padding: 0;
    }
    &[class*="col-"] {
      .mega-col-nav {
        padding: 0 15px;
      }
      > .mega-dropdown-inner > .row > .mega-col-nav {
        border-right: 1px solid rgba(41, 37, 36, 0.08);
        padding: 0;
        > .mega-inner {
          padding: 0;
          .mega-group {
            padding: 25px 30px;
          }
        }
        li .nav-child li {
          a {
            padding: 0 !important;
            font-size: 15px;
            font-weight: 300;
            line-height: 20px;
            margin: 15px 0 !important;
            .transition(all 0.3s linear 0s);
            border: none !important;
            &:hover {
              background: transparent;
              color: #292524;
              padding-left: 8px !important;
            }
          }
          &:first-child {
            a {
              margin-top: 0 !important;
            }
          }
          &:last-child {
            a {
              margin-bottom: 0 !important;
            }
          }
        }
      }
      .mega-nav > li a.mega-group-title {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        text-transform: uppercase;
        color: #292524;
        font-size: 16px;
        padding: 0 !important;
        background: transparent !important;
        margin-bottom: 20px !important;
        border: none !important;
      }
    }
  }
}

.caret {
  border: none;
  width: auto;
  height: 20px;
  margin-left: 3px;
  vertical-align: inherit;
  &:before {
    font-family: FontAwesome;
    content: '\f107';
    font-size: 13px;
  }
}

/*Footer*/
.tz__block__footer {
  font-size: 15px;
  font-weight: 300;
  p {
    color: #999999;
  }
  color: #999999;
  .sppb-social-share-style-solid ul li a, .sppb-social-share-style-custom ul li a {
    padding: 0;
    i {
      height: 40px;
      line-height: 40px;
      width: 40px;
    }
  }
  .sppb-social-share-wrap {
    margin: 0;
  }
  .sppb-social-items-wrap {
    padding: 0;
  }
  .border-bottom-1 {
    position: relative;
    &:before {
      height: 10px;
      width: 33.33333333%;
      content: '';
      margin-left: 33.33333333%;
      position: absolute;
      left: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.1);
    }
  }
}

.tz__footer--top {
  > div > div {
    display: flex;
    align-items: flex-end;
  }
}

.tz__block_call {
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  color: #a3a3a3;
  font-size: 24px;
  strong {
    color: @mainColor;
    font-weight: 400;
  }
}

.tz__block__copyright {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  strong, a {
    font-weight: 400;
    color: @mainColor;
  }
}

/* Breadcrumb */
.tz__block {
  &__breadcrumb {

    .tz__last__item {
      color: #ffffff;
    }
  }
}

.tz__breadcrumb {
  //background: #f8f6f1;
  padding: 110px 0;
  position: relative;
  &:after {
    content: '';
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &__content {
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    max-width: 1200px;
    padding: 0 15px;
    margin: auto;
    > * {
      width: 50%;
    }
  }
  &--right {
    text-align: right;
    .breadcrumb {
      background: transparent;
      .border-radius(0);
      padding: 0;
      margin: 0;
      font-weight: 300;
      font-size: 15px;
      > li + li:before {
        color: #cacaca;
        padding: 0 0 0 5px;
      }
      li {
        color: #cacaca;
      }
      a {
        color: #cacaca !important;
      }
    }
  }
  &__item--title {
    line-height: 40px;
    margin: 0;
  }
}

/* Blog Category */
.blog {
  .items-row {
    .tz__blog__title {
      h3 {
        font-size: 22px;
      }
    }
  }
  .category-desc {
    img {
      float: left;
      margin-right: 20px;
    }
  }
  .tz__blog__media {
    margin-bottom: 40px;
    img {
      width: 100%;
    }
  }
  .tz__blog__content {
    margin-bottom: 40px;
    display: flex;
    align-items: flex-start;
    .icons {
      .btn {
        padding: 6px 12px;
        font-size: 12px;
        border: 1px solid @gray-lighter;
      }
    }
    &--left {
      min-width: 100px;
      padding-right: 30px;
    }
    &--right {
      width: 100%;
      margin-top: -20px;
      .article-info {
        margin-top: -15px;
      }
      .content_rating {
        margin-bottom: 15px;
      }
      .tags {
        margin-bottom: 20px;
      }
      .readmore {
        a.btn {
          background: @background-btn;
          color: @mainTextColor;
          padding: 7px 20px;
          &:hover {
            background: @mainColor;
            color: #fff;
            border: 1px solid #ffb00d;
          }
        }
      }
    }
  }
  .items-leading {
    .tz__blog__title {
      h3 {
        font-size: 30px;
      }
    }
  }
  .items-row {
    .tz__blog__title {
      h3 {
        font-size: 22px;
      }
    }
    .tz__blog__media {
      margin-bottom: 25px;
    }
  }
  .tz__blog {
    &__published {
      background: @mainColor;
      color: #ffffff;
      text-transform: uppercase;
      time {
        display: block;
        padding: 10px;
      }
      .day {
        font-size: 30px;
        font-weight: 600;
        display: block;
        line-height: 1em;
      }
      .month {
        font-size: 13px;
        font-weight: 400;
        display: block;
      }
    }
    &__hits {
      margin-top: 10px;
      background: transparent;
      text-transform: uppercase;
      min-height: 65px;
      padding: 10px 0;
      border: 1px solid #e9e9e9;
      color: #a3a3a3;
      [class*="fa"] {
        color: @mainColor;
      }
    }
  }
}
.service {
  .tz__blog__content {
    .tz__blog__title {
      h3:after {
        background: rgba(0, 0, 0, 0.1) none repeat scroll 0 0;
        content: "";
        display: block;
        height: 1px;
        margin-top: 15px;
        width: 50px;
        margin-bottom: 35px;
      }
    }
    .page-header {
      h2:after {
        background: rgba(0, 0, 0, 0.1) none repeat scroll 0 0;
        content: "";
        display: block;
        height: 1px;
        margin-top: 15px;
        width: 50px;
        margin-bottom: 20px;
      }
    }
  }
}

/* Article */
.item-page {
  >div {
    margin-bottom: 40px;
  }
  .category-desc {
    img {
      float: left;
      margin-right: 20px;
    }
  }
  .tz__blog__media {
    margin-bottom: 40px;
  }
  .tz__blog__content {
    display: flex;
    align-items: flex-start;
    .icons {
      .btn {
        padding: 6px 12px;
        font-size: 12px;
        border: 1px solid @gray-lighter;
      }
    }
    &--left {
      min-width: 100px;
      padding-right: 30px;
    }
    &--right {
      width: 100%;
      margin-top: -20px;
      .page-header {
        margin: 0;
        border: none;
        h2 {
          margin-bottom: 10px;
        }
      }
      .article-info {
        margin-top: -15px;
        font-style: italic;
      }
      .content_rating {
        margin-bottom: 15px;
      }
      .tags {
        margin-bottom: 20px;
        display: inline-block;
      }
      .readmore {
        a.btn {
          background: @background-btn;
          color: @mainTextColor;
          padding: 7px 20px;
        }
      }
    }
  }
  .tz__blog {
    &__published {
      background: @mainColor;
      color: #ffffff;
      text-transform: uppercase;
      time {
        display: block;
        padding: 10px;
      }
      .day {
        font-size: 30px;
        font-weight: 600;
        display: block;
        line-height: 1em;
      }
      .month {
        font-size: 13px;
        font-weight: 400;
        display: block;
      }
    }
    &__hits {
      margin-top: 10px;
      background: transparent;
      text-transform: uppercase;
      min-height: 65px;
      padding: 10px 0;
      border: 1px solid #e9e9e9;
      color: #a3a3a3;
      [class*="fa"] {
        color: @mainColor;
      }
    }
  }
}
#tpPortfolio {
  margin: -10px;
  .tpFilter {
    margin-bottom: 25px;
  }
  #portfolio {
    .tpCategories{
      font-style: italic;
      font-weight: 300;
      color: #f8f6f1;
      a {
        font-style: italic;
      }
    }
  }
  #tz_append {
    padding: 10px;
    a {
      color: #222;
      border-radius: 5px;
      &:hover {
        background: #ffb00d;
        border: 1px solid #ffb00d;
        color: #fff;
      }
    }
  }
}
.tpItemPage {
  .tp-item-tools {
    .btn {
      color: #222;
      padding: 7px 10px;
    }
  }
  .tpArticleMedia {
    margin-bottom: 40px;
  }
  .headExtra {
    color: #292524;
    font-family: "Poppins",sans-serif;
  }
  .tpArticleExtraField {
    ul {
      padding: 0;
    }
    li.tp_extrafield-item {
      padding: 3px 0 !important;
    }
    .tp_extrafield-label {
      margin-right: 10px;
      &:after {
        content: ":";
      }
    }
    .tp_extrafield-value {
      font-weight: 300;
    }
  }
  .tpSocialShare {
    a {
      padding: 12px;
      height: 40px;
      width: 40px;
    }
  }
  .tpHead {
    .tpMeta {
      font-weight: 300;
      font-style: italic;
    }
    .tpTitle {
      font-size: 27px;
    }
  }
  .tpArticleAuthor {
    .AuthorAvatar {
      padding-right: 0;
    }
  }
}

.tzService {
  .TzInner {
    margin-bottom: 40px;
    .TzArticleMedia {
      margin-bottom: 25px !important;
    }
    .TzPortfolioTitle {
      font-family: "Poppins", sans-serif;
      a {
        color: #292524;
        text-decoration: none;
      }
    }
    .TzPortfolioDescription {
      a.TzPortfolioReadmore {
        background: #f8f6f1 none repeat scroll 0 0 !important;
        border-radius: 0 ;
        padding: 7px 20px;
        color: #686868 !important;
        text-transform: uppercase;
        &:hover {
          background: #ffb00d none repeat scroll 0 0 !important;
          color: #fff !important;
          border: 1px solid #ffb00d !important;
        }
      }
    }
  }
}

.display-flex {
  display: flex;
  align-items: center;
}
.contact {
  h4{
    margin-bottom: 40px;
    &:after {
      display: block;
      content: '';
      height: 1px;
      width: 35px;
      background: rgba(0, 0, 0, 0.1);
      margin-top: 15px;
    }
  }
  input, textarea {
    border-color: @bordeColor;
    background: @inputbg;
    border-radius: 0;
    box-shadow: none;
  }
  button {
    color: #ffffff;
    text-transform: uppercase;
    font-size: 12px;
  }
  .contact-address {
    dd {
      margin-left: 20px;
      .contact-head {
        font-family: "poppins", sans-serif;
        text-transform: uppercase;
        font-weight: 600;
        color: @mainHeadColor;
      }
    }
    > div {
      margin: 20px 0;
    }
  }
}

.tp-bullets.tp-thumbs {
  width: 100% !important;
  margin-top: -50% !important;
  padding: 1px !important;
  background: @mainColor;
  bottom: 0 !important;
  .tp-mask {
    width: 100% !important;
    .tp-thumbcontainer {
      width: 100% !important;
      left: 0 !important;
      right: 0;
      .bullet {
        width: 25% !important;
        border-left: 1px solid @mainColor;
      }
    }
  }
}
.tp-caption a {
  color: #fff;
}

.big-shadow img {
  box-shadow: 50px -50px 0 rgba(0,0,0,0.03);
}

.heading-left {
  .sppb-addon-title {
    &:after {
      background: rgba(0, 0, 0, 0.1) none repeat scroll 0 0;
      content: "";
      display: block;
      height: 1px;
      margin-top: 25px;
      width: 100px;
      margin-bottom: 35px;
    }
  }
}

.heading-center {
  .sppb-addon-title {
    &:after {
      background: rgba(0, 0, 0, 0.1) none repeat scroll 0 0;
      content: "";
      display: block;
      height: 1px;
      width: 50px;
      margin: 20px auto 30px;
    }
  }
}

.heading-center-white {
  .sppb-addon-title {
    color: #fff;
    &:after {
      background: rgba(255, 255, 255, 0.1) none repeat scroll 0 0;
      content: "";
      display: block;
      height: 1px;
      width: 50px;
      margin: 20px auto 30px;
    }
  }
}

.sppb-addon-title {
  span {
    color: @mainColor;
  }
}

.subtext {
  .sppb-addon-content {
    max-width: 600px;
    margin: 0 auto;
  }
}

.sppb-testimonial-message {
  color: #cacaca;
  width: 80%;
  margin: 0 auto;
}
.sppb-testimonial-client {
  color: #ffffff;
  span {
    font-weight: 300;
    font-style: italic;
    display: block;
  }
}

.sppb-addon-feature {
  .sppb-addon-text {
    line-height: 1.7;
  }
}

.tz-testimonial-inner {
  .tz-testimonial {
    img.tz-img-responsive {
      max-width: 100px;
    }
    .owl-item.active.center {
      .tz-testimonial-message {
        visibility: visible;
        opacity: 1;
      }
    }
    .tz-testimonial-message {
      width: 840px;
      -webkit-transform: translate(-50%, 0);
      -khtml-transform: translate(-50%, 0);
      -moz-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
      -o-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
      position: relative;
      left: 50%;
      visibility: hidden;
      opacity: 0;
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
    }
  }
}
.slick-slide {
  padding: 0;
  &:focus {
    outline: none;
  }
}
.testimonial {
  .quote {
    text-align: center;
    font-size: 30px;
    color: #ffffff;
    position: absolute;
    top: 0;
    left: 48%;
    .fa-quote-right {
      color: @mainColor;
      font-size: 66px;
      opacity: 0.1;
    }
  }
  .slick-center {
    .testimonial__tz-client {
      opacity: 1;
      visibility: visible;
    }
    .testimonial__img-responsive {
      &:after {
        content: none;
      }
    }
  }
  .custom-navigation {
    position: relative;
    width: 70px;
    z-index: 0;
    margin: 0 auto;
    span {
      color: #aaaaaa;
      font-size: 48px;
      cursor: pointer;
      &:hover {
        color: @mainColor;
      }
    }
  }
}
.testimonial__slider-nav {
  color: #4184d8;
  margin: 40px 0 25px !important;
  background: transparent !important;
  border: 0 !important;
  position: relative;
  .slick-slider {
    padding-top: 20px;
  }
}
.testimonial__tz-message {
  color: #e1e1e1;
  text-align: center;
  font-weight: 300;
  line-height: 28px;
  font-size: 14px;
  max-width: 910px;
  margin: 0 auto;
}
.testimonial__slider-for {
  z-index: 1;
  max-width: 370px;
  margin: 0 auto;
  background: transparent !important;
  border: 0 !important;
  .slick-list {
    padding: 0 !important;
  }
}
.testimonial__img-responsive {
  max-width: 63px;
  text-align: center;
  margin: 0 auto;
  position: relative;
  img {
    max-width: 100%;
    z-index: 0;
    border: 2px solid #ffffff;
  }
  &:after {
    content: "";
    z-index: 1;
    background: rgba(255, 255, 255, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    -webkit-transition: all 300s ease;
    -moz-transition: all 300s ease;
    -o-transition: all 300s ease;
    transition: all 300s ease;
  }
}
.testimonial__tz-client {
  opacity: 0;
  visibility: hidden;
  span {
    display: block;
    text-align: center;
    color: #aaaaaa;
    font-weight: 300;
    line-height: 21px;
    font-size: 11px;
    font-style: italic;
  }
}
.testimonial__pro-client-name {
  text-transform: none;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  margin: 20px 0 0;
  color: #ffffff;
}
.testimonial__next {
  float: left;
}
.testimonial__pre {
  float: right;
}

.home-latestnews {
  li {
    list-style: none;
    .media {
      margin-bottom: 30px;
    }
    h6 {
      margin-bottom: 10px;
      font-weight: 500;
    }
    .info {
      margin-bottom: 15px;
      font-size: 12px;
      color: lighten(@mainTextColor, 20%);
      font-style: italic;
    }
  }
}

.client {
  .owl-stage {
    display: flex;
    align-items: center;
    .owl-item {
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
      img {
        opacity: 0.5;
      }
      &:hover img{
        opacity: 1;
      }
    }
  }
}

.tz_portfolio_plus_articles {
  .TzInner {
    margin: 0;
    padding: 0;
    border: none;
  }
}
.tz_portfolio_plus_articles {
  .tpFilter {
    margin-bottom: 45px;
    text-align: center;
    .option-combo {
      display: inline-block;
      a.tpFilterItem {
        transition: all .2s cubic-bezier(.445,.05,.55,.95);
        transition-delay: 50ms;
        color: #999;
        text-decoration: none;
        margin-left: 40px;
        font-size: 15px;
        outline: 0;
        &:first-child {
          margin-left: 0;
        }
        &:hover {
          color: #292524;
        }
      }
      .selected {
        border-left: 3px solid #fbc441;
        background: 0 0;
        color: #292524 !important;
        padding-left: 15px;
      }
    }
  }
}

//slider override
.tp-dottedoverlay.twoxtwo {
  background: rgba(0,0,0,0.5);
}

.tparrows {
  top: 40% !important;
}