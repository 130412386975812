@media (max-width: @screen-sm-max) {
  .big-shadow {
    .sppb-addon-single-image-container {
      width: 100%;
      margin-bottom: 30px;
      img {
        box-shadow: none;
        width: 100%;
      }
    }
  }
  .sppb-addon-feature {
    margin-bottom: 30px;
    text-align: left;
    .sppb-addon-title {
      &:after {
        margin: 20px 0 30px;
      }
    }
  }
  .sppb-addon-single-image {
    text-align: center;
  }
  .tz__block__footer {
    .tz__footer--top {
      > div {
        > div {
          display: block;
          .sppb-social-share-wrap {
            text-align: center !important;
          }
        }
      }
    }
    .sppb-row {
      > div {
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
  #tzlogo {
    display: block;
    margin-bottom: 30px;
  }
  .tz__block__menu {
    > div > .row {
      display: block;
      padding: 30px 0 0;
      >div {
        text-align: center;
      }
    }
    .plazart-mainnav .navbar-nav > li > a {
      padding-top: 0;
      padding-bottom: 30px;
    }
  }
}

@media (max-width: @screen-xs-max) {
  .tz__block__menu > div > .row {
    display: block;
    position: relative;
    #tz-logo {
      position: absolute;
      top: 7px;
      left: 0;
    }
    .navbar-toggle {
      margin-right: 0;
      padding: 7px 13px;
    }
    .plazart-mainnav {
      .navbar-nav {
        transition: all .2s cubic-bezier(.445,.05,.55,.95);
        margin: 7.5px 0;
        > li {
          display: block;
          text-align: left;
          margin: 0;
          a {
            padding: 10px 5px;
          }
        }
      }
      .dropdown-menu[class*="col-"]{
        > .mega-dropdown-inner > .row > .mega-col-nav {
          border-right: none;
          > .mega-inner .mega-group {
            padding: 10px 5px;
          }
        }
        .mega-nav > li a.mega-group-title {
          font-size: 14px;
        }
      }
    }
  }
  .tz__breadcrumb__content {
    flex-direction: column;
    align-items: left;
    > div {
      width: 100%;
      text-align: left;
    }
  }
  .sppb-addon-cta {
    .sppb-btn {
      width: 100%;
    }
  }
  .tpItemPage {
    .col-xs-12 {
      width: 100%;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .testimonial {
    .custom-navigation {
      width: 70px;
      margin-top: 0;
      margin-bottom: 0;
    }
    .quote {
      left: 44%;
    }
  }
  .tz__block__footer {
    .sppb-row {
      > div {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .tz__blog__content--left {
    display: none;
  }
}