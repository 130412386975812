.padding-lr-0 {
  padding-left: 0;
  padding-right: 0;
}

.padding-l-0 {
  padding-left: 0;
}

.padding-r-0 {
  padding-right: 0;
}

.container {
  max-width: 1200px;
  width: auto;
}
.sppb-container-inner{
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
}
.tz-module{
  .sppb-container-inner {
    max-width: 1200px;
  }
}

.tz-container {
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
  width: auto;
}

.width-auto {
  width: auto;
  display: inline-block;
}

.block__child--width--auto {
  .sppb-column-addons {
    > [id*="sppb-addon-"] {
      width: auto;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.flex-basline {
  display: flex;
  align-items: baseline;
}

.tz__title {
  margin-bottom: 60px;
  h5 {
    font-weight: 500;
    &:after {
      content: '';
      height: 1px;
      background: rgba(41, 37, 36, 0.1);
      width: 50px;
      display: block;
      margin-top: 15px;
    }
  }
}

/* Typo */
body {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  color: #686868;
  font-size: 15px;
  line-height: 1.7;
}

p {
  color: #686868;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  margin-bottom: 28px;
}

a {
  color: inherit;
  &:hover, &:focus, &:active {
    text-decoration: none;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #292524;
  line-height: 1.2em;
  text-transform: uppercase;
}

.h1, .h2, .h3, h1, h2, h3, .h4, .h5, .h6, h4, h5, h6 {
  margin-bottom: 20px;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 18px;
}

b, strong {
  font-weight: 500;
}

.color-white {
  color: #ffffff;
}

.font-weight-400 {
  font-weight: 400;
}

.table-bordered > thead > tr > td, .table-bordered > thead > tr > th {
  border-bottom-width: 1px;
}

.text-center {
  td, th {
    text-align: center;
  }
}

table {
  td, th {
    font-size: 15px;
    font-weight: 300;
    padding: 15px 0;
    color: #666666;
  }
  th {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #292524;
  }
  &.style2 {
    th {
      background: @mainColor;
      color: #ffffff;
      border-color: @mainColor;
    }

    > tbody > tr > td, > tbody > tr > th, > tfoot > tr > td, > tfoot > tr > th, > thead > tr > td, > thead > tr > th {
      border: none;
    }
    thead > tr {
      border-left: 1px solid @mainColor;
      border-right: 1px solid @mainColor;
    }
    tbody {
      > tr {
        border-left: 1px solid #e9e9e9;
        border-right: 1px solid #e9e9e9;
        > td {
          border-bottom: 1px solid #e9e9e9;
        }
      }
    }
  }
  &.table-striped > tbody > tr:nth-of-type(odd) {
    background: #f8f6f1;
  }
}

.alert {
  .border-radius(0);
  font-weight: 400;
  font-size: 15px;
  padding: 14px 15px;
  .close {
    font-size: 14px;
    line-height: 21px;
    opacity: 1;
    text-shadow: none;
  }
  &:before {
    font-family: FontAwesome;
    font-size: 14px;
    display: inline-block;
    margin-left: 10px;
    margin-right: 15px;
    min-width: 14px;
  }
  &-danger {
    background: #fcd2d4;
    border-color: #fcd2d4;
    color: #ea464e;
    &:before {
      content: "\f071";
    }
    .close {
      color: #ea464e;
    }

  }
  &-success {
    background: #d8f0da;
    border-color: #d8f0da;
    color: #31c23d;
    &:before {
      content: "\f00c";
    }
    .close {
      color: #31c23d;
    }
  }
  &-warning {
    background: #fff3c1;
    border-color: #fff3c1;
    color: #c4a93a;
    &:before {
      content: "\f05a";
    }
    .close {
      color: #c4a93a;
    }
  }
  &-info {
    background: #d3effa;
    border-color: #d3effa;
    color: #46a0c3;
    &:before {
      content: "\f0e7";
    }
    .close {
      color: #46a0c3;
    }
  }
  &-message {
    background: #d3effa;
    border-color: #d3effa;
    color: #46a0c3;
    &:before {
      content: "\f05a";
    }
    .close {
      color: #46a0c3;
    }
  }
  &-style-2 {
    background: transparent;
    &.alert-danger {
      border-color: #ea464e;
      color: #ea464e;
    }
    &.alert-success {
      border-color: #31c23d;
      color: #31c23d;
    }
    &.alert-warning {
      border-color: #c4a93a;
      color: #c4a93a;
    }
    &.alert-info {
      border-color: #46a0c3;
      color: #46a0c3;
    }
    &.alert-message {
      border-color: #46a0c3;
      color: #46a0c3;
    }
  }
}

/* Tabs */
.sppb-addon-tab {
  .sppb-nav-lines {
    border-bottom-width: 1px;
    > li {
      > a {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #a3a3a3;
        padding: 10px 20px;
      }
    }
    > li.active > a, > li.active > a:hover, > li.active > a:focus {
      border-bottom-width: 2px;
      border-bottom-color: @mainColor;
      margin-bottom: -1px;
      color: #292524;
    }
  }
  .sppb-nav-tabs {
    > li {
      > a {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #a3a3a3;
        padding: 10px 30px;
        border-right: 1px solid transparent;
        border-left-color: transparent;
        border-top-color: transparent;
        background: transparent;
      }
    }
    > li.active > a, > li.active > a:hover, > li.active > a:focus {
      border-right: 1px solid #e9e9e9;
      border-left-color: #e9e9e9;
      border-top-color: #e9e9e9;
      border-bottom-color: #ffffff;
      color: #292524;
    }
  }
  &.sppb-addon-tab-pills {
    .sppb-tab {
      display: flex;
    }
  }
  .sppb-nav-pills {
    border-right: 1px solid #e9e9e9;
    > li {
      float: none;
      min-width: 85px;
      > a {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #a3a3a3;
        padding: 15px 15px 15px 0;
        background: transparent;
        border-right-width: 2px;
        border-right-color: transparent;
        border-right-style: solid;
        margin-right: -1px;
        .border-radius(0);
      }
    }
    > li.active > a, > li.active > a:hover, > li.active > a:focus {
      border-right-color: @mainColor;
      color: #292524;
      background: transparent;
    }
  }
  .sppb-tab-content {
    &.sppb-nav-lines-content {
      margin-top: 25px;
    }
    &.sppb-nav-tabs-content {
      padding: 22px 0 0;
      .sppb-tab-pane {
        margin: 0 30px 30px;
      }
    }
    &.sppb-nav-pills-content {
      padding-left: 45px;
      margin-top: 10px;
    }
  }
}

/* Accordion */
.sppb-addon-accordion {
  .sppb-panel-default {
    > .sppb-panel-heading {
      background: transparent;
      .sppb-panel-title {
        color: #292524;
      }
      .sppb-toggle-direction {
        color: #292524;
        font-size: 16px;
      }
    }
  }
  .sppb-panel-faq {
    border-color: #e9e9e9;
    &:last-child{
      border-bottom: 1px solid @bordeColor;
    }
    &:first-child {
      border-top: 1px solid @bordeColor;
    }
    > .sppb-panel-heading {
      background: #f8f6f1;
      border-left: 1px solid @bordeColor;
      border-right: 1px solid @bordeColor;
      .sppb-panel-title {
        color: #292524;
      }
      .sppb-toggle-direction {
        color: #292524;
        font-size: 16px;
      }
    }
    .sppb-panel-body {
      border-left: 1px solid @bordeColor;
      border-right: 1px solid @bordeColor;
    }
  }
  .sppb-panel-heading {
    padding: 15px 20px;
    .sppb-panel-title {
      //color: #292524;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      font-size: 14px;
    }
    .sppb-toggle-direction {
      font-size: 16px;
    }
  }
  .sppb-panel-body {
    padding: 25px;
    margin: 0 0 0;
  }
}

/* Button */
.sppb-btn {
  .transition(all 0.3s ease 0s);
  .border-radius(0);
  border: 1px solid;
  font-size: 15px;
  line-height: 1.6em;
  padding: 7px 35px;
  font-weight: 400;
  &.sppb-btn-lg {
    font-size: 18px;
    line-height: 1.6em;
    padding: 10px 45px;
  }
  &.sppb-btn-sm {
    font-size: 12px;
    line-height: 1.2em;
    padding: 7px 30px;
  }
  &.sppb-btn-xs {
    font-size: 10px;
    line-height: 1.2em;
    padding: 5px 20px;
  }
  &.sppb-btn-xlg {
    font-size: 20px;
    line-height: 1.6em;
    padding: 13px 55px;
  }
  &.sppb-btn-link {
    padding: 7px 35px !important;
    border: 1px solid #e9e9e9 !important;
    color: #999999;
    &:hover, &:active, &:focus {
      border-color: #000000 !important;
      color: #000000;
    }
    &.sppb-btn-lg {
      padding: 10px 45px !important;
    }
    &.sppb-btn-sm {
      padding: 7px 30px !important;
    }
    &.sppb-btn-xs {
      padding: 5px 20px !important;
    }
    &.sppb-btn-xlg {
      padding: 13px 55px !important;
    }
  }
  &.sppb-btn-primary {
    background: #ffb00d;
    border-color: #ffb00d;
    &:hover, &:active, &:focus {
      background: darken(#ffb00d, 5%);
      border-color: darken(#ffb00d, 5%);
    }
  }
  &.sppb-btn-default {
    background: #292524;
    border-color: #292524;
    color: #ffffff;
    &:hover, &:active, &:focus {
      background: darken(#292524, 10%);
      border-color: darken(#292524, 10%);
    }
  }
  &.sppb-btn-warning {
    background: lighten(#e5a10e, 20%);
    border-color: lighten(#e5a10e, 20%);
    &:hover, &:active, &:focus {
      background: lighten(#e5a10e, 15%);
      border-color: lighten(#e5a10e, 15%);
    }
  }
  &.sppb-btn-info {
    background: #00c1f8;
    border-color: #00c1f8;
    &:hover, &:active, &:focus {
      background: darken(#00c1f8, 5%);
      border-color: darken(#00c1f8, 5%);
    }
  }
  &.sppb-btn-success {
    background: #2ac535;
    border-color: #2ac535;
    &:hover, &:active, &:focus {
      background: darken(#2ac535, 5%);
      border-color: darken(#2ac535, 5%);
    }
  }
  &.sppb-btn-danger {
    background: #e41827;
    border-color: #e41827;
    &:hover, &:active, &:focus {
      background: darken(#e41827, 5%);
      border-color: darken(#e41827, 5%);
    }
  }
}

.btn {
  .transition(all 0.3s ease 0s);
  .border-radius(0);
  border: 1px solid #e9e9e9;
  font-size: 15px;
  line-height: 1.6em;
  background: #f8f6f1 none repeat scroll 0 0;
  color: #686868;
  padding: 7px 20px;
  font-weight: 400;
  &.btn-lg {
    font-size: 18px;
    line-height: 1.6em;
    padding: 10px 45px;
  }
  &.btn-sm {
    font-size: 12px;
    line-height: 1.2em;
    padding: 7px 30px;
  }
  &.btn-xs {
    font-size: 10px;
    line-height: 1.2em;
    padding: 5px 20px;
  }
  &.btn-xlg {
    font-size: 20px;
    line-height: 1.6em;
    padding: 13px 55px;
  }
  &.btn-link {
    padding: 7px 35px !important;
    border: 1px solid #e9e9e9 !important;
    color: #999999;
    &:hover, &:active, &:focus {
      border-color: #000000 !important;
      color: #000000;
    }
    &.btn-lg {
      padding: 10px 45px !important;
    }
    &.btn-sm {
      padding: 7px 30px !important;
    }
    &.btn-xs {
      padding: 5px 20px !important;
    }
    &.btn-xlg {
      padding: 13px 55px !important;
    }
  }
  &.btn-primary {
    background: #ffb00d;
    border-color: #ffb00d;
    &:hover, &:active, &:focus {
      background: darken(#ffb00d, 5%);
      border-color: darken(#ffb00d, 5%);
    }
  }
  &.btn-default {
    background: #292524;
    border-color: #292524;
    color: #ffffff;
    &:hover, &:active, &:focus {
      background: darken(#292524, 10%);
      border-color: darken(#292524, 10%);
    }
  }
  &.btn-warning {
    background: lighten(#e5a10e, 20%);
    border-color: lighten(#e5a10e, 20%);
    &:hover, &:active, &:focus {
      background: lighten(#e5a10e, 15%);
      border-color: lighten(#e5a10e, 15%);
    }
  }
  &.btn-info {
    background: #00c1f8;
    border-color: #00c1f8;
    &:hover, &:active, &:focus {
      background: darken(#00c1f8, 5%);
      border-color: darken(#00c1f8, 5%);
    }
  }
  &.btn-success {
    background: #2ac535;
    border-color: #2ac535;
    &:hover, &:active, &:focus {
      background: darken(#2ac535, 5%);
      border-color: darken(#2ac535, 5%);
    }
  }
  &.btn-danger {
    background: #e41827;
    border-color: #e41827;
    &:hover, &:active, &:focus {
      background: darken(#e41827, 5%);
      border-color: darken(#e41827, 5%);
    }
  }
}

/* Progress Bar */
.tz__progress {
  &.tz--style-1 {
    .sppb-progress {
      margin-bottom: 25px;
    }
    &.tz--type-2 {
      .sppb-progress {
        margin-top: 30px;
        background: transparent;
      }
      .sppb-progress-label,
      .sppb-progress-bar {
        margin: 0;
      }
      .sppb-progress-bar {
        padding: 0;
      }
      .tz__text {
        bottom: 15px;
      }
      .tz__percent {
        bottom: 15px;
      }
    }
  }
  &.tz--style-2 {
    .sppb-progress-bar {
      padding-left: 20px;
      padding-right: 10px;
    }
    &.tz--type-1 {
      .sppb-progress {
        background: transparent;
      }
    }
  }
}

.sppb-progress {
  background: #f8f6f1;
  .border-radius(0);
  position: relative;
  overflow: visible;
  .tz__text {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .tz__percent {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.sppb-progress-label {
  font-weight: 300;
  font-size: 15px;
  color: #292524;
  margin-bottom: 15px;
  > span {
    font-weight: 300;
  }
}

.sppb-progress-bar {
  text-align: left;
  //padding-left: 20px;
  font-weight: 300;
  font-size: 15px;
  color: #ffffff;
  margin-bottom: 15px;
  position: relative;
  overflow: visible;
  &.sppb-progress-bar-primary {
    background: #ffb00d;
  }
  &.sppb-progress-bar-striped {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 40px 40px;
  }
}


ul {
  padding-left: 15px;
  li {
    margin: 0;
  }
  &.list-icon {
    list-style: none;
    padding: 0;
    [class*="fa"] {
      position: absolute;
      left: 0;
      top: 5px;
      color: @mainColor;
      font-size: 14px;
    }
    li {
      position: relative;
      padding-left: 30px;
      margin-bottom: 10px;
    }
  }
}

.tz__block__info--flex {
  display: flex;
  align-items: center;
  .tz__block--left {
    text-align: left;
    width: 40%;
  }
  .tz__block--right {
    text-align: right;
    width: 60%;
  }
}

.nav-pills > li.active > a {
  color: @mainColor;
  background: none;
  &:hover {
    color: @mainColor;
    background: none;
  }
  &:focus {
    color: @mainColor;
    background: none;
  }
}

/*Border*/
.border-bottom-1 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/*Title Module*/
.module-inner {
  .module-title {
    position: relative;
    margin-bottom: 40px;
    margin-top: 0;
    span {
      display: block;
    }
    &:after {
      display: block;
      content: '';
      height: 1px;
      width: 30px;
      background: rgba(0, 0, 0, 0.1);
      margin-top: 15px;
    }
  }
}

.form-control {
  height: 40px;
}